<style lang="scss" scoped>
.card-footer {
  z-index: 9;
  height: 12%;
  position: fixed;
  top: 88%;
  width: 35%;
}

h5 {
  span {
    text-transform: capitalize;
  }
}
#email-smtp-config {
  @mixin can-toggle-branding(
    $can-toggle-off-color: #4c4c4c,
    $can-toggle-on-color: #4c4c4c,
    $can-toggle-inactive-text: rgba(#fff, 0.5),
    $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
  ) {
    input[type="checkbox"] {
      &[disabled] ~ label {
        color: rgba($can-toggle-off-color, 0.5);
      }

      &:focus ~ label,
      &:hover ~ label {
        .can-toggle__switch {
          background-color: $can-toggle-off-color;
          &:after {
            color: darken($can-toggle-off-color, 10%);
          }
        }
      }
      &:hover ~ label {
        color: darken($can-toggle-off-color, 5%);
      }
      &:checked {
        ~ label {
          &:hover {
            color: darken($can-toggle-on-color, 3%);
          }

          .can-toggle__switch {
            background-color: lighten($can-toggle-on-color, 5%);
            &:after {
              color: darken($can-toggle-on-color, 5%);
            }
          }
        }

        &:focus,
        &:hover {
          ~ label {
            .can-toggle__switch {
              background-color: $can-toggle-on-color;
              &:after {
                color: darken($can-toggle-on-color, 10%);
              }
            }
          }
        }
      }
    }

    label {
      .can-toggle__label-text {
        flex: 1;
      }

      .can-toggle__switch {
        transition: background-color 0.3s $can-toggle-transition;
        background: lighten($can-toggle-off-color, 5%);
        &:before {
          color: $can-toggle-inactive-text;
        }
        &:after {
          // Autoprefixer choked here, so making the prefixes explicit
          -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
          transition: transform 0.3s $can-toggle-transition;
          color: $can-toggle-off-color;
        }
      }
    }
  }

  @mixin can-toggle-appearance(
    $can-toggle-width: 134px,
    $can-toggle-height: 36px,
    $can-toggle-border-radius: 4px,
    $can-toggle-offset: 2px,
    $can-toggle-label-font-size: 14px,
    $can-toggle-switch-font-size: 12px,
    $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
  ) {
    $can-toggle-switch-width: $can-toggle-width/2;

    input[type="checkbox"] {
      &:focus ~ label,
      &:hover ~ label {
        .can-toggle__switch {
          &:after {
            box-shadow: $can-toggle-shadow;
          }
        }
      }

      &:checked {
        ~ label {
          .can-toggle__switch {
            &:after {
              transform: translate3d(
                $can-toggle-width -
                  ($can-toggle-switch-width + $can-toggle-offset),
                0,
                0
              );
            }
          }
        }
        &:focus,
        &:hover {
          ~ label {
            .can-toggle__switch {
              &:after {
                box-shadow: $can-toggle-shadow;
              }
            }
          }
        }
      }
    }

    label {
      font-size: $can-toggle-label-font-size;

      .can-toggle__switch {
        height: $can-toggle-height;
        flex: 0 0 $can-toggle-width;
        border-radius: $can-toggle-border-radius;

        &:before {
          left: $can-toggle-width/2;
          font-size: $can-toggle-switch-font-size;
          line-height: $can-toggle-height;
          width: $can-toggle-width/2;
          padding: 0 12px;
        }

        &:after {
          top: $can-toggle-offset;
          left: $can-toggle-offset;
          border-radius: $can-toggle-border-radius/2;
          width: $can-toggle-switch-width - $can-toggle-offset;
          line-height: $can-toggle-height - ($can-toggle-offset * 2);
          font-size: $can-toggle-switch-font-size;
        }

        &:hover {
          &:after {
            box-shadow: $can-toggle-shadow;
          }
        }
      }
    }
  }

  .can-toggle {
    position: relative;
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }
    //overflow: hidden;

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;

      &[disabled] ~ label {
        pointer-events: none;
        .can-toggle__switch {
          opacity: 0.4;
        }
      }

      &:checked {
        ~ label {
          .can-toggle__switch {
            &:before {
              content: attr(data-unchecked);
              left: 0;
            }

            &:after {
              content: attr(data-checked);
            }
          }
        }

        &:focus,
        &:hover {
          ~ label {
          }
        }
      }
    }

    label {
      user-select: none;
      position: relative;
      display: flex;
      align-items: center;

      .can-toggle__label-text {
        flex: 1;
        padding-left: 32px;
      }

      .can-toggle__switch {
        position: relative;

        &:before {
          content: attr(data-checked);
          position: absolute;
          top: 0;
          text-transform: uppercase;
          text-align: center;
        }

        &:after {
          content: attr(data-unchecked);
          position: absolute;
          z-index: 5;
          text-transform: uppercase;
          text-align: center;
          background: white;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    // Default values for .can-toggle class
    @include can-toggle-branding;
    @include can-toggle-appearance;

    // Create toggles of different sizes by overriding the can-toggle-appearance mixin defaults
    &.can-toggle--size-small {
      @include can-toggle-appearance(
        90px,
        // Toggle width
          28px,
        // Toggle height
          2px,
        // Toggle border radius
          1px,
        // Offset (distance btw switch and box)
          13px,
        // Label font size
          10px,
        // Switch font size
          0 2px 2px rgba(black, 0.4) / Switch shadow on hover/focus
      );
    }
  }
}
</style>
<template>
  <div class="row" id="email-smtp-config">
    <div class="full-load" v-if="processing">
      <h1>
        Please Wait... <br />
        Sending test email
      </h1>
    </div>
    <div class="col-md-6 offset-3">
      <form @submit="onSubmit">
        <div v-for="(time, index) in timers" :key="index" class="mb-5">
          <div class="card form-body">
            <div class="card-body">
              <legend>
                <h5 class="">
                  Timer Setup <span>({{ time.warning_type }})</span>
                </h5>
              </legend>
              <hr />

              <div class="form-group">
                <label for="">Title <span class="text-danger">*</span></label>
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  v-model="time.title"
                  :class="
                    errors[index].title && errors[index].title[0] != ''
                      ? 'border-danger'
                      : ''
                  "
                />
                <span
                  v-if="errors[index].title && errors[index].title[0] != ''"
                  class="text-danger"
                  >{{ errors[index].title[0] }}</span
                >
              </div>

              <div class="form-group">
                <label for="">Message <span class="text-danger">*</span></label>
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  :class="
                    errors[index].warning_msg &&
                    errors[index].warning_msg[0] != ''
                      ? 'border-danger'
                      : ''
                  "
                  v-model="time.warning_msg"
                />
                <span
                  v-if="
                    errors[index].warning_msg &&
                      errors[index].warning_msg[0] != ''
                  "
                  class="text-danger"
                  >{{ errors[index].warning_msg[0] }}</span
                >
              </div>

              <div class="form-group">
                <label for=""
                  >Time Limit <span class="text-danger">*</span></label
                >
                <input
                  type="number"
                  name=""
                  class="form-control"
                  id=""
                  :class="
                    errors[index].time_limit &&
                    errors[index].time_limit[0] != ''
                      ? 'border-danger'
                      : ''
                  "
                  min="1"
                  v-model="time.time_limit"
                />
                <span
                  v-if="
                    errors[index].time_limit &&
                      errors[index].time_limit[0] != ''
                  "
                  class="text-danger"
                  >{{ errors[index].time_limit[0] }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success"
            style="float: right"
            ref="kt_save_configure"
            type="submit"
          >
            <i class="fa fa-check"></i>Update
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";

import {
  STORE_TIMER_SETUP,
  GET_TIMER_SETUP,
  UPDATE_TIMER_SETUP
} from "@/core/services/store/actions.type";

export default {
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Setting",
          route: ""
        },
        {
          id: 3,
          title: "Configure Timer",
          route: ""
        }
      ],
      errors: [
        {
          title: [""],
          warning_msg: [""],
          time_limit: [""]
        },
        {
          title: [""],
          warning_msg: [""],
          time_limit: [""]
        }
      ],
      processing: false,

      timers: [
        {
          warning_type: "warning",
          title: "",
          warning_msg: "",
          time_limit: 5
        },
        {
          warning_type: "expiry",
          title: "",
          warning_msg: "",
          time_limit: 10
        }
      ],
      is_edit: 0
    };
  },

  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(GET_TIMER_SETUP).then(data => {
      if (data.results.length > 0) {
        this.timers = data.results;
      }
      this.is_edit = data.results.length > 0 ? data.results[0].id : 0;
    });
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();

      if (
        parseInt(this.timers[0].time_limit) >=
        parseInt(this.timers[1].time_limit)
      ) {
        this.errors = [
          {
            title: [""],
            warning_msg: [""],
            time_limit: [
              "Warning  time limit must be less than expiry time limit"
            ]
          },
          {
            title: [""],
            warning_msg: [""],
            time_limit: [""]
          }
        ];
        return;
      } else {
        this.errors = [
          {
            title: [""],
            warning_msg: [""],
            time_limit: [""]
          },
          {
            title: [""],
            warning_msg: [""],
            time_limit: [""]
          }
        ];
      }

      const submitButton = this.$refs["kt_save_configure"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      if (this.is_edit == 0) {
        this.$store
          .dispatch(STORE_TIMER_SETUP, { data: this.timers })
          .then(data => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
            this.is_edit = data.length > 0 ? data[0].id : 0;
            this.timers = data;

            this.$toastr.s("Timer successfully added");
          })
          .catch(err => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
            this.errors = err;
          });
      } else {
        this.$store
          .dispatch(UPDATE_TIMER_SETUP, { data: this.timers })
          .then(data => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
            if (this.id == 0) {
              this.id = data.id;
            }
            this.$toastr.s("Timer successfully updated");
          })
          .catch(err => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
            this.errors = err;
          });
      }
    }
  }
};
</script>

<style></style>

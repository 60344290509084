var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",attrs:{"id":"email-smtp-config"}},[(_vm.processing)?_c('div',{staticClass:"full-load"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"col-md-6 offset-3"},[_c('form',{on:{"submit":_vm.onSubmit}},[_vm._l((_vm.timers),function(time,index){return _c('div',{key:index,staticClass:"mb-5"},[_c('div',{staticClass:"card form-body"},[_c('div',{staticClass:"card-body"},[_c('legend',[_c('h5',{},[_vm._v(" Timer Setup "),_c('span',[_vm._v("("+_vm._s(time.warning_type)+")")])])]),_c('hr'),_c('div',{staticClass:"form-group"},[_vm._m(1,true),_c('input',{directives:[{name:"model",rawName:"v-model",value:(time.title),expression:"time.title"}],staticClass:"form-control",class:_vm.errors[index].title && _vm.errors[index].title[0] != ''
                    ? 'border-danger'
                    : '',attrs:{"type":"text","name":"","id":""},domProps:{"value":(time.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(time, "title", $event.target.value)}}}),(_vm.errors[index].title && _vm.errors[index].title[0] != '')?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors[index].title[0]))]):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(2,true),_c('input',{directives:[{name:"model",rawName:"v-model",value:(time.warning_msg),expression:"time.warning_msg"}],staticClass:"form-control",class:_vm.errors[index].warning_msg &&
                  _vm.errors[index].warning_msg[0] != ''
                    ? 'border-danger'
                    : '',attrs:{"type":"text","name":"","id":""},domProps:{"value":(time.warning_msg)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(time, "warning_msg", $event.target.value)}}}),(
                  _vm.errors[index].warning_msg &&
                    _vm.errors[index].warning_msg[0] != ''
                )?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors[index].warning_msg[0]))]):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(3,true),_c('input',{directives:[{name:"model",rawName:"v-model",value:(time.time_limit),expression:"time.time_limit"}],staticClass:"form-control",class:_vm.errors[index].time_limit &&
                  _vm.errors[index].time_limit[0] != ''
                    ? 'border-danger'
                    : '',attrs:{"type":"number","name":"","id":"","min":"1"},domProps:{"value":(time.time_limit)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(time, "time_limit", $event.target.value)}}}),(
                  _vm.errors[index].time_limit &&
                    _vm.errors[index].time_limit[0] != ''
                )?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors[index].time_limit[0]))]):_vm._e()])])])])}),_c('div',{staticClass:"card-footer"},[_c('button',{ref:"kt_save_configure",staticClass:"btn btn-success",staticStyle:{"float":"right"},attrs:{"type":"submit"}},[_c('i',{staticClass:"fa fa-check"}),_vm._v("Update ")])])],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v(" Please Wait... "),_c('br'),_vm._v(" Sending test email ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":""}},[_vm._v("Title "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":""}},[_vm._v("Message "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":""}},[_vm._v("Time Limit "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }